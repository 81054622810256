<template>
  <div class="box" id="box">
    <div class="flashcardHeader d-flex">
      <div class="pl-3 return">
        <div
          v-if="!showNotes"
          @click="goBack"
          style="color: #42a16a; cursor: pointer"
        >
          <i class="fas fa-chevron-left"></i>
        </div>
        <div
          v-else
          @click="showNotes = false"
          style="color: #42a16a; cursor: pointer"
        >
          <i class="fas fa-chevron-left"></i>
        </div>
      </div>
      <div class="headerTitle">
        <span style="padding-right: 25px;">Flashcards</span>
      </div>
      <div id="toggleFlashcard" @click="toggleClick" class="mr-1">
        <!-- <div
          class="spanFlashCard"
          id="topFlashCard"
          :class="{ active: isHamburgerActive }"
        ></div>
        <div
          class="spanFlashCard"
          id="middleFlashCard"
          :class="{ active: isHamburgerActive }"
        ></div>
        <div
          class="spanFlashCard"
          id="bottomFlashCard"
          :class="{ active: isHamburgerActive }"
        ></div> -->
      </div>
    </div>
    <div class="flashcardHeaderSecond d-flex">
      <div>{{ title }}</div>
    </div>

    <template v-if="!finished">
      <template v-if="!showNotes">
        <div class="controlRow d-flex">
          <div class="remove d-flex" @click="removeWord">
            <i class="fas fa-times"></i>
          </div>
          <div class="star">
            <div
              style="width:8%; float: right;"
              class="mr-4"
              @click="starDrawer = true"
            >
              <template v-if="showStared">
                <div>
                  <i class="fas fa-star starIsSelected"></i>
                </div>
              </template>
              <template v-else>
                <div>
                  <i class="far fa-star starHover"></i>
                </div>
              </template>
            </div>
          </div>
          <el-drawer
            title="添加到我的收藏夾"
            :visible.sync="starDrawer"
            direction="rtl"
            size="70%"
          >
            <div class="starLists" v-if="lists">
              <div class="list" v-for="(list, index) in lists" :key="index">
                <div class="list-content">
                  <div class="list-title">{{ list.title }}</div>
                  <div>
                    <span v-show="stared(list.id)" @click="removeStar(list.id)"
                      ><i class="fas fa-star starIsSelected"></i
                    ></span>
                    <span v-show="!stared(list.id)" @click="addStar(list.id)"
                      ><i class="far fa-star starHover"></i
                    ></span>
                  </div>
                </div>
                <hr />
              </div>
              <div class="list" @click="showNewList">
                <div class="list-content">
                  Add New<span
                    ><i class="el-icon-plus avatar-uploader-icon"></i
                  ></span>
                </div>
                <hr />
              </div>
            </div>
          </el-drawer>
        </div>
        <div
          class="wordDisplay"
          v-if="currentSelectedContent[0] && totalLength > 0"
          @click="toggleDetail(currentSelectedContent[0])"
        >
          <div v-show="!showDetail" class="text-center">
            <div style="margin-top:20px">
              {{ currentSelectedContent[0].word }}
            </div>
            <div class="voice">
              <el-button
                type="primary"
                style="font-size:20px;margin-right:20px"
                @click.stop="speakWord(currentSelectedContent[0].word, 'en-US')"
                >US&nbsp;<i class="fas fa-volume-up"></i
              ></el-button>
              <el-button
                type="primary"
                style="font-size:20px"
                @click.stop="speakWord(currentSelectedContent[0].word, 'en-GB')"
                >UK&nbsp;<i class="fas fa-volume-up"></i
              ></el-button>
            </div>
          </div>
          <div v-show="showDetail" class="wordDetail">
            <div style="display: flex; align-items: center;">
              <h1 style="margin-bottom:10px; color: #42a16a">
                {{ currentSelectedContent[0].word }}
              </h1>
              <div>
                <el-button
                  type="primary"
                  size="mini"
                  style="font-size:16px; margin-left:12px"
                  @click.stop="
                    speakWord(currentSelectedContent[0].word, 'en-US')
                  "
                  >US&nbsp;<i class="fas fa-volume-up"></i
                ></el-button>
                <el-button
                  type="primary"
                  size="mini"
                  style="font-size:16px"
                  @click.stop="
                    speakWord(currentSelectedContent[0].word, 'en-GB')
                  "
                  >UK&nbsp;<i class="fas fa-volume-up"></i
                ></el-button>
              </div>
            </div>
            <el-divider content-position="left" style="font-size:20px">
              <i class="fas fa-book book"></i> {{ $t("flashCards.Memorization technique") }}
            </el-divider>
            <!-- <el-alert
              class="mt-3 mb-2 pb-3"
              title=""
              type="success"
              :closable="false"
              v-if="currentSelectedContent[0].memorization_technique"
            >
              {{ currentSelectedContent[0].memorization_technique }}
            </el-alert> -->
            <div
              class="mt-3 mb-3 note-card note-stared"
              v-if="currentSelectedContent[0].memorization_technique !== '' && currentSelectedContent[0].memorization_technique !== null"
            >
              <div class="note-content pt-2 pb-2">
                <div>
                  {{ currentSelectedContent[0].memorization_technique }}
                </div>
                <div>
                  <small class="starIsSelected">
                    <b>Ivy Way{{ $t("flashCards.Memorization technique") }}</b>
                  </small>
                </div>
              </div>
              <div><el-divider direction="vertical"></el-divider></div>
              <div class="note-star pt-2 pb-2" @click.stop="saveWordLike(currentSelectedContent[0])">
                <div class="thumbs" v-show="currentSelectedContent[0].liked_by_me === 1">
                  <i class="fas fa-thumbs-up starIsSelected"></i>
                </div>
                <div class="thumbs" v-show="currentSelectedContent[0].liked_by_me === 0">
                  <i class="far fa-thumbs-up"></i>
                </div>
                {{ currentSelectedContent[0].star }}
              </div>
            </div>
            <div
              class="mt-3 mb-3 note-card note-stared"
              v-if="myMnemonic.id && myMnemonic.id > 0"
            >
              <div
                class="note-star pt-2 pb-2"
                style="font-size:16px"
                @click.stop="showEditNote = true"
              >
                <i class="fas fa-edit"></i>
              </div>
              <div><el-divider direction="vertical"></el-divider></div>
              <div class="note-content pt-2 pb-2">
                <div>
                  {{ myMnemonic.mnemonic }}
                </div>
                <div>
                  <small class="starIsSelected">
                    <b>{{ $t("flashCards.My technique") }}</b>
                  </small>
                </div>
              </div>
              <div><el-divider direction="vertical"></el-divider></div>
              <div
                class="note-star pt-2 pb-2"
                @click.stop="saveLike(myMnemonic)"
              >
                <div class="thumbs" v-show="myMnemonic.liked_by_me === 1">
                  <i class="fas fa-thumbs-up starIsSelected"></i>
                </div>
                <div class="thumbs" v-show="myMnemonic.liked_by_me === 0">
                  <i class="far fa-thumbs-up"></i>
                </div>
                {{ myMnemonic.likes }}
              </div>
            </div>
            <div v-else>
              <div v-if="mnemonics.length > 0">
                <div
                  class="mt-3 mb-2 note-card note-stared"
                  v-for="(item, index) in mnemonics.slice(0, 1)"
                  :key="index"
                >
                  <div class="note-content pt-2 pb-2">
                    <div>
                      {{ item.mnemonic }}
                    </div>
                    <div>
                      <small
                        >作者：{{ item.user.first_name }}
                        {{ item.user.last_name }}</small
                      >
                    </div>
                  </div>
                  <div><el-divider direction="vertical"></el-divider></div>
                  <div class="note-star pt-2 pb-2" @click.stop="saveLike(item)">
                    <div class="thumbs" v-show="item.liked_by_me === 1">
                      <i class="fas fa-thumbs-up starIsSelected"></i>
                    </div>
                    <div class="thumbs" v-show="item.liked_by_me === 0">
                      <i class="far fa-thumbs-up"></i>
                    </div>
                    {{ item.likes + item.super_likes }}
                  </div>
                </div>
              </div>
              <div
                class="text-center"
                style="font-size:16px;margin-top:20px"
                @click.stop="showEditNote = true"
              >
                <el-button size="small">
                  <i class="fas fa-plus"></i> 新增我的記憶法
                </el-button>
              </div>
            </div>
            <template v-if="mnemonics.length > 0">
              <el-collapse-transition>
                <div class="openModal" v-show="openNote">
                  <!-- <div
                    v-if="!(myMnemonic.id && myMnemonic.id > 0)"
                    class="text-center"
                    style="font-size:16px"
                    @click.stop="showEditNote = true"
                  >
                    <el-button>
                      <i class="fas fa-plus"></i>
                    </el-button>
                  </div> -->
                  <div
                    class="mt-3 mb-2 note-card note-stared"
                    v-for="(item, index) in mnemonics.slice(0, 3)"
                    :key="index"
                  >
                    <div class="note-content pt-2 pb-2">
                      <div>
                        {{ item.mnemonic }}
                      </div>
                      <div>
                        <small
                          >作者：{{ item.user.first_name }}
                          {{ item.user.last_name }}</small
                        >
                      </div>
                    </div>
                    <div><el-divider direction="vertical"></el-divider></div>
                    <div
                      class="note-star pt-2 pb-2"
                      @click.stop="saveLike(item)"
                    >
                      <div class="thumbs" v-show="item.liked_by_me === 1">
                        <i class="fas fa-thumbs-up starIsSelected"></i>
                      </div>
                      <div class="thumbs" v-show="item.liked_by_me === 0">
                        <i class="far fa-thumbs-up"></i>
                      </div>
                      {{ item.likes + item.super_likes }}
                    </div>
                  </div>
                  <div class="text-right">
                    <el-button
                      type="text"
                      size="small"
                      @click.stop="showNotes = true"
                      >查看更多 <i class="fas fa-chevron-right"></i
                    ></el-button>
                  </div>
                </div>
              </el-collapse-transition>
              <div
                class="demo-block-control"
                @click.stop="openNote = !openNote"
              >
                <div v-show="!openNote">
                  <i class="fas fa-chevron-down"></i>
                </div>
                <div v-show="openNote">
                  <i class="fas fa-chevron-up"></i>
                </div>
              </div>
            </template>
            <div class="" style="margin: 40px 0 50px;">
              <el-divider content-position="left">
                <i class="fas fa-book book"></i> 意思
              </el-divider>
              <div class="mt-3 mb-3 note-card note-stared">
                <div class="note-content pt-2 pb-2">
                  <div>
                    ( {{ currentSelectedContent[0].pos }} )
                    {{ currentSelectedContent[0].meaning.en }}
                    {{ currentSelectedContent[0].meaning.tw }}
                  </div>
                </div>
                <!-- <div><el-divider direction="vertical"></el-divider></div>
                <div
                  class="note-star pt-2 pb-2"
                  style="font-size:16px"
                  @click.stop="showEditNote = true"
                >
                  <i class="fas fa-edit"></i>
                </div> -->
              </div>
              <!-- <el-collapse-transition>
                <div class="openModal" v-show="openWord"></div>
              </el-collapse-transition>
              <div
                class="demo-block-control"
                @click.stop="openWord = !openWord"
              >
                <div v-show="!openWord">
                  <i class="fas fa-chevron-down"></i>
                </div>
                <div v-show="openWord">
                  <i class="fas fa-chevron-up"></i>
                </div>
              </div> -->
            </div>
            <el-divider content-position="left">
              <i class="fas fa-book book"></i> 例句
            </el-divider>
            <div>
              <!-- <span class="exButton">例句</span> -->
              {{ currentSelectedContent[0].sentence }}
            </div>
          </div>
        </div>
        <div class="wordRemaining">
          <span class="pr-4">
            Words remaining:
            <span style="color: red;">{{ totalLength }}</span>
          </span>
        </div>
      </template>
      <template v-else>
        <NotesList :mnemonics="mnemonics" />
      </template>
      <!-- three buttons -->
      <div class="levelButtons">
        <div class="d-flex flex-buttons" v-if="!show">
          <div style="width: 100%; color: #d9534f;" class="btnStyle">
            <div style="background-color: #d9534f;" class="btnBar"></div>
            <div class="btnClick" @click="putToRandomPlace('hard')">Hard</div>
          </div>
          <div style="width: 100%; color: #5bc0de;" class="btnStyle">
            <div style="background-color: #5bc0de;" class="btnBar"></div>
            <div class="btnClick" @click="putToRandomPlace('good')">Good</div>
          </div>
          <div style="width: 100%; color: #42a16a;" class="btnStyle">
            <div style="background-color: #42a16a;" class="btnBar"></div>
            <div class="btnClick" @click="putToRandomPlace('easy')">Easy</div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="controlRow d-flex mt-4"></div>
      <div class="wordDisplay d-flex">
        <div style="color: #42a16a;">
          <div style="text-align: center">All done! Yay!</div>
          <div style="text-align: center">
            Time spent: {{ totalHour }}:{{ totalMinute }}:{{ totalSecond }}
          </div>
        </div>
      </div>
      <!-- two buttons -->
      <div class="levelButtons">
        <div class="d-flex flex-buttons" v-if="show">
          <div style="width: 100%; color: #5bc0de;" class="btnStyle">
            <div style="background-color: #5bc0de;" class="btnBar"></div>
            <div class="btnClick" @click="goBack">Go Back</div>
          </div>
          <div style="width: 100%; color: #42a16a;" class="btnStyle">
            <div style="background-color: #42a16a;" class="btnBar"></div>
            <div class="btnClick" @click="restart">Restart</div>
          </div>
        </div>
      </div>
    </template>

    <!-- drawer -->
    <el-drawer
      :visible.sync="drawer"
      :before-close="handleClose"
      direction="rtl"
      :append-to-body="true"
      :size="widthPercent"
    >
      <div class="d-block">
        <div class="drawerItem p-3">
          <h5 class="pt-1">
            <i class="fas fa-cog"></i>
            <span class="ml-3">Settings</span>
          </h5>
        </div>

        <div class="drawerItem p-3">
          <h5 class="pt-1">
            <i class="far fa-question-circle"></i>
            <span class="ml-3">Help</span>
          </h5>
        </div>
      </div>
    </el-drawer>

    <!-- dialog -->
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      :width="widthPercentDialog"
      :before-close="dialogContinue"
      :close-on-click-modal="false"
    >
      <span>{{ $t("flashCards.Do you wish to continue memorizing?") }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="danger" @click="dialogLeave">
          {{ $t("flashCards.Quit") }}
        </el-button>
        <el-button type="primary" @click="dialogContinue">
          {{ $t("flashCards.Continue") }}
        </el-button>
      </span>
    </el-dialog>
    <el-dialog title="新增" :visible.sync="starDialogVisible" width="92%">
      <div>
        <div class="demo-input-suffix" style="margin-bottom:10px">
          <h6>Title:</h6>
          <el-input placeholder="Title" v-model="activeList.title"> </el-input>
        </div>
        <div class="demo-input-suffix">
          <h6>Description:</h6>
          <el-input
            type="textarea"
            :rows="4"
            placeholder="Description"
            v-model="activeList.intro"
          >
          </el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer text-center">
        <el-button type="primary" @click="createList">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="$t('flashCards.My technique')" :visible.sync="showEditNote" width="95%">
      <div>
        <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
          <el-tab-pane name="Text">
            <span slot="label"><i class="el-icon-date"></i> Text</span>
            <el-input
              type="textarea"
              :rows="5"
              v-model="myMnemonic.mnemonic"
            >
            </el-input>
          </el-tab-pane>
          <el-tab-pane name="second">
            <span slot="label"><i class="el-icon-date"></i> Text</span>
          </el-tab-pane>
          <el-tab-pane name="third">
            <span slot="label"><i class="el-icon-date"></i> Text</span>
          </el-tab-pane>
        </el-tabs>

        <el-checkbox v-model="is_public" style="margin-top:10px">
          {{ $t("flashCards.Share with other students") }}
        </el-checkbox>
        &nbsp;
        <el-tooltip
          class="item"
          effect="dark"
          :content="$t('flashCards.our memorization technique is public to help other students remember this word')"
          placement="top-start"
        >
          <span><i class="fas fa-question-circle"></i></span>
        </el-tooltip>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="danger"
          size="small"
          @click="deleteMnemonic(myMnemonic.id)"
          v-if="myMnemonic.id && myMnemonic.id > 0"
        >
          {{ $t("courses.delete") }}
        </el-button>
        <el-button type="primary" size="small" @click="saveMnemonic">
          {{ $t("courses.save") }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import VocabList from "@/apis/myVocabLists";
import FlashCards from "@/apis/flashcards";
import NotesList from "@/views/flashCards/components/NotesList.vue";

import _ from "lodash";
export default {
  components: { NotesList },
  props: ["isFlashCard"],
  data() {
    return {
      title: "",
      showEditNote: false,
      showNotes: false,
      starDialogVisible: false,
      activeList: {
        id: 0,
        title: "",
        intro: ""
      },
      starDrawer: false,
      finished: false,
      currentSelectedContent: [],
      totalLength: NaN,
      showDetail: false,
      show: false,
      startTime: Date.now(),
      starSelected: false,
      starHover: false,
      isLeaveFalshcard: false,
      isHamburgerActive: false,
      drawer: false,
      widthPercent: "30%",
      widthPercentDialog: "60%",
      finishRemove: true,
      vocab_backs: [],
      currentRecordVocab: this.defaultRecord(),
      currentTimeSecond: 0,
      isOvertime: false,
      timer: null,
      dialogVisible: false,
      totalTime: 0,
      totalHour: "",
      totalMinute: "",
      totalSecond: "",
      lists: [],
      utterThis: new SpeechSynthesisUtterance(""),
      synth: window.speechSynthesis,
      openWord: false,
      openNote: false,
      mnemonics: [],
      myMnemonic: {
        mnemonic: "",
        mnemonic_img_url: "",
        is_public: 0
      },
      is_public: false
    };
  },
  computed: {
    ...mapState("user", ["profile"]),
    showStared() {
      return this.currentSelectedContent[0]?.vocab_collect_ids?.length > 0;
    }
  },
  watch: {
    is_public(val) {
      if (val) {
        this.myMnemonic.is_public = 1;
      } else {
        this.myMnemonic.is_public = 0;
      }
    },
    totalLength(newVal) {
      if (newVal === 0) {
        // 將資料傳回後端
        this.$store.dispatch("flashcards/recordVocab", {
          vocab_backs: this.vocab_backs
        });
        this.calculateTimeFormat();
        this.finished = true;
        this.show = true;
      }
    },
    // 如果 isLeaveFalshcard 有變化則呼叫此 method，此時使用者尚未離開，瀏覽器上方會出現 alert 詢問使用者是否確定離開
    isLeave(newVal) {
      if (newVal) {
        // 因為無法知道使用者是否會選擇離開，因此開始新的計時
        this.startTime = Date.now();
        this.isLeaveFalshcard = false;
      }
    }
  },

  async created() {
    if (this.$route.query.selectedList) {
      await this.$store.dispatch("flashcards/getVocabs", {
        vocab_lists: this.$route.query.selectedList.split(",")
      });
      this.title = this.$route.params.title;
    }
    this.currentSelectedContent = _.cloneDeep(
      this.$store.getters["flashcards/getCurrentSelectedVocabs"]
    );

    this.shuffleArray(this.currentSelectedContent);
    this.totalLength = this.currentSelectedContent.length;

    // listen to current size of screen
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth < 400) {
        this.widthPercent = "50%";
        // this.widthPercentDialog = "70%";
      } else if (this.windowWidth < 800) {
        this.widthPercent = "30%";
        // this.widthPercentDialog = "50%";
      } else {
        this.widthPercent = "30%";
        // this.widthPercentDialog = "30%";
      }
    });

    // timer - 持續記錄時間，由 currentTimeSecond 控制，只要在其他地方設 currentTimeSecond 為 0
    // 此 timer 就會從頭開始計算
    this.timer = setInterval(myTimer, 1000);
    const thisVar = this;
    function myTimer() {
      if (thisVar.currentTimeSecond / 1000 >= 120 && !thisVar.finished) {
        thisVar.dialogVisible = true;
        thisVar.isOvertime = true;
      }
      // console.log(thisVar.currentTimeSecond / 1000 + " sec...");
      thisVar.currentTimeSecond += 1000;
    }
  },
  mounted() {
    this.$nextTick(() => {
      document.getElementById("box").addEventListener("click", () => {
        this.startTime = Date.now();
        this.currentTimeSecond = 0;
      });
    });
    const main = document.getElementsByClassName("el-main");
    main[0].style.padding = "0";
    // 監視是否關閉視窗
    window.onbeforeunload = () => {
      // 因為瀏覽器版本緣故，目前只能塞少量指令在 onbeforeunload 裡 (還不知道具體原因)
      // 因此只放 isLeave，由 watch 監聽 isLeaveFalshcard 的變化再做 post 處理
      this.isLeaveFalshcard = true;
      return "Are you sure you want to leave?";
    };
    this.getLists();
    // this.getMnemonics();
  },
  beforeDestroy() {
    // 如果 totalLength 是 0 代表已經結束，因此不需要再 call 一次 post API
    // 如果 totalLength 不小於初始長度，則不需要 call API
    if (
      this.totalLength !== 0 &&
      this.totalLength < this.getCurrentSelectedVocabs.length
    ) {
      this.$store.dispatch("flashcards/recordVocab", {
        vocab_backs: this.vocab_backs
      });
    }
    if (window.innerWidth > 992) {
      // 離開此頁面後將 padding 恢復原狀
      const main = document.getElementsByClassName("el-main");
      main[0].style.padding = "100px 0px 0px 0px";
    } else {
      const main = document.getElementsByClassName("el-main");
      main[0].style.padding = "60px 0px 0px 0px";
    }
    // 不再監聽 onbeforeunload
    window.onbeforeunload = null;
    // 清除 timer
    clearInterval(this.timer);
  },
  methods: {
    async getLists() {
      const res = await VocabList.getVocabLists();
      this.lists = res.vocab_collects;
    },
    async getMnemonics() {
      console.log(this.currentSelectedContent[0]);
      const res = await FlashCards.getMnemonics({
        vocab_front_id: this.currentSelectedContent[0].vocab_front_id
      });
      this.mnemonics = res.mnemonics;
      this.myMnemonic = {
        mnemonic: "",
        mnemonic_img_url: "",
        is_public: 0
      };
      let myIndex = null;
      this.mnemonics.forEach((val, index) => {
        if (val.user_id === this.profile.id) {
          this.myMnemonic = val;
          myIndex = index;
          if (this.myMnemonic.is_public === 1) {
            this.is_public = true;
          } else {
            this.is_public = false;
          }
        }
      });
      if (myIndex !== null) {
        this.mnemonics.splice(myIndex, 1);
      }
      this.mnemonics = _.orderBy(
        this.mnemonics,
        ["liked_by_me", "likes"],
        ["desc", "desc"]
      );
    },
    saveMnemonic() {
      if (this.myMnemonic && this.myMnemonic.id > 0) {
        this.updateMnemonic(this.myMnemonic.id);
      } else {
        this.createMnemonic();
      }
      this.showEditNote = false;
    },
    async deleteMnemonic(id) {
      await FlashCards.deleteMnemonic(id);
      this.getMnemonics();
      this.showEditNote = false;
    },
    async updateMnemonic(id) {
      const res = await FlashCards.updateMnemonic(id, {
        mnemonic: this.myMnemonic.mnemonic,
        mnemonic_img_url: this.myMnemonic.mnemonic_img_url,
        is_public: this.myMnemonic.is_public
      });
      this.getMnemonics();
    },
    async createMnemonic() {
      const res = await FlashCards.createMnemonic({
        vocab_front_id: this.currentSelectedContent[0].vocab_front_id,
        mnemonic: this.myMnemonic.mnemonic,
        mnemonic_img_url: this.myMnemonic.mnemonic_img_url,
        is_public: this.myMnemonic.is_public
      });
      this.getMnemonics();
    },
    async saveLike(item) {
      if (item.liked_by_me === 1) {
        item.liked_by_me = 0;
        if (item.likes > 0) {
          item.likes = item.likes - 1;
        }
      } else {
        item.liked_by_me = 1;
        item.likes = item.likes + 1;
      }
      await FlashCards.saveLike(item.id);
      // this.getMnemonics();
    },
    defaultRecord() {
      return {
        vocab_back_id: "",
        usage_time: "",
        is_stared: ""
      };
    },
    // shuffle array
    shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
    },
    toggleClick() {
      document.documentElement.style.overflow = "auto";
      this.isHamburgerActive = true;
      this.drawer = true;
    },
    handleClose() {
      document.documentElement.style.overflow = "hidden";
      this.isHamburgerActive = false;
      this.drawer = false;
    },

    // 按左上角叉，將單字從 array 中移除
    removeWord() {
      // 移除第一項
      const curVocab = this.currentSelectedContent.shift();
      this.totalLength = this.currentSelectedContent.length;

      // 將目前已結束單字資料整理過後放入 vocab_backs
      this.currentRecordVocab.vocab_back_id = curVocab.vocab_back_id;
      this.currentRecordVocab.is_stared = this.starSelected ? 1 : 0;
      // 紀錄目前時間
      this.currentRecordVocab.usage_time = this.isOvertime
        ? 120
        : this.calculateTimeDiff();

      // 計算總時間
      this.totalTime += this.currentRecordVocab.usage_time;
      this.vocab_backs.push(this.currentRecordVocab);

      // 重置 isOvertime
      this.isOvertime = false;
      // 重置 showDetail
      this.showDetail = false;
      // 重置 star
      this.starSelected = false;
      // 重新開始計時
      this.startTime = Date.now();
      this.currentRecordVocab = this.defaultRecord();
      // 重置秒數
      this.currentTimeSecond = 0;
    },
    async toggleDetail(word) {
      this.showDetail = !this.showDetail;
      console.log(this.showDetail);
     
      if(this.showDetail) {
        const liked = await FlashCards.likedByme(word.vocab_back_id);
        // this.currentSelectedContent[0] = {...word};
        this.currentSelectedContent[0]["liked_by_me"] = null;
        this.currentSelectedContent[0]["liked_by_me"] = liked.liked_by_me;
        this.getMnemonics();
      }
    },
    putToRandomPlace(level) {
      // 如果是 hard 那就隨機放入前 1/3 (不包括首項，因此用 Math.ceil() 避開 0)
      if (level === "hard") {
        const firstElement = this.currentSelectedContent.shift();
        const index = Math.ceil(
          Math.random() * Math.round(this.totalLength / 3)
        );
        this.currentSelectedContent.splice(index, 0, firstElement);
        // 如果是 good 那就隨機放入中間 1/3
      } else if (level === "good") {
        const firstElement = this.currentSelectedContent.shift();
        const index =
          Math.floor(Math.random() * Math.round(this.totalLength / 3)) +
          Math.round(this.totalLength / 3);
        this.currentSelectedContent.splice(index, 0, firstElement);
        // 如果是 easy 就隨機放入後 1/3 (直接用總數減前兩個區間的值，避免計算時出現四捨五入問題)
      } else if (level === "easy") {
        const firstElement = this.currentSelectedContent.shift();
        const index =
          Math.floor(
            Math.random() *
              (this.totalLength - Math.round(this.totalLength / 3) * 2)
          ) +
          Math.round(this.totalLength / 3) * 2;
        this.currentSelectedContent.splice(index, 0, firstElement);
      }
      // 重置 showDetail
      this.showDetail = false;
      // 重置 star
      this.starSelected = false;
      // 重新開始計時
      this.startTime = Date.now();
      this.currentTimeSecond = 0;
      this.getMnemonics();
    },
    calculateTimeDiff() {
      let endTime = Date.now();
      let timeDiff = (endTime - this.startTime) / 1000;
      return Math.round(timeDiff);
    },
    goBack() {
      // this.$router.push({
      //   name: "FlashCards"
      // });
      this.$router.back(-1);
    },
    speakWord(word, lang) {
      this.utterThis.lang = lang;
      this.utterThis.text = word;
      this.synth.speak(this.utterThis);
    },
    restart() {
      this.currentSelectedContent = _.cloneDeep(this.getCurrentSelectedVocabs);
      this.shuffleArray(this.currentSelectedContent);
      this.totalLength = this.currentSelectedContent.length;
      this.finished = false;
      this.showDetail = false;
      this.show = false;
      this.starSelected = false;
      this.startTime = Date.now();
      this.currentTimeSecond = 0;
      this.vocab_backs = [];
    },
    dialogContinue() {
      this.startTime = Date.now();
      this.currentTimeSecond = 0;
      this.dialogVisible = false;
    },
    dialogLeave() {
      this.startTime = Date.now();
      this.currentTimeSecond = 0;
      this.dialogVisible = false;
      this.goBack();
    },
    calculateTimeFormat() {
      const tempHour = Math.floor(this.totalTime / 3600);
      if (tempHour === 0) {
        this.totalHour = "00";
      } else if (tempHour < 10) {
        this.totalHour = "0" + tempHour.toString();
      } else {
        this.totalHour = tempHour.toString();
      }
      this.totalTime -= tempHour * 3600;
      const tempMinute = Math.floor(this.totalTime / 60);
      if (tempMinute === 0) {
        this.totalMinute = "00";
      } else if (tempMinute < 10) {
        this.totalMinute = "0" + tempMinute.toString();
      } else {
        this.totalMinute = tempMinute.toString();
      }
      const tempSecond = this.totalTime - tempMinute * 60;
      if (tempSecond === 0) {
        this.totalSecond = "00";
      } else if (tempSecond < 10) {
        this.totalSecond = "0" + tempSecond.toString();
      } else {
        this.totalSecond = tempSecond.toString();
      }
    },
    stared(id) {
      let stared = false;
      if (this.currentSelectedContent[0].vocab_collect_ids) {
        this.currentSelectedContent[0].vocab_collect_ids.forEach(list => {
          if (list === id) {
            stared = true;
          }
        });
      }
      return stared;
    },
    async saveWordLike(item) {
      if (item.liked_by_me === 1) {
        item.liked_by_me = 0;
        if (item.star > 0) {
          item.star = item.star - 1;
        }
      } else {
        item.liked_by_me = 1;
        item.star = item.star + 1;
      }
      await FlashCards.saveWordLike(item.vocab_back_id);
    },
    async addStar(id) {
      let arr = [];
      arr.push(this.currentSelectedContent[0].vocab_front_id);
      const res = await VocabList.addVocabToList(id, {
        vocab_front_id: arr
      });
      if (this.currentSelectedContent[0].vocab_collect_ids) {
        this.currentSelectedContent[0].vocab_collect_ids.push(id);
      } else {
        let new_arr = [];
        new_arr.push(id);
        this.currentSelectedContent[0].vocab_collect_ids = new_arr;
      }

      // this.$message({
      //   message: "已收藏！",
      //   type: "success"
      // });
      this.$notify({
        message: "已收藏。",
        position: "bottom-right",
        duration: 1000,
        offset: 80
      });
    },
    async removeStar(id) {
      const res = await VocabList.deleteVocabFromList(
        id,
        this.currentSelectedContent[0].vocab_front_id
      );
      this.currentSelectedContent[0].vocab_collect_ids.splice(
        this.currentSelectedContent[0].vocab_collect_ids.indexOf(id),
        1
      );
      this.$notify({
        message: "已取消收藏。",
        position: "bottom-right",
        duration: 1000,
        offset: 80
      });
    },
    showNewList() {
      this.starDialogVisible = true;
      this.activeList = {
        id: 0,
        title: "",
        intro: ""
      };
    },
    async createList() {
      if (this.activeList.title !== "") {
        await VocabList.createVocabList({
          title: this.activeList.title,
          intro: this.activeList.intro
        });
        this.getLists();
        this.starDialogVisible = false;
      } else {
        this.$message({
          message: "`Title` 不能為空！",
          type: "warning"
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/navbarFlashcard.scss";

.box {
  height: 100vh;
  display: grid;
  grid-template-rows: 55px 22px 55px auto 48px 71px;
}

// .active-card {
//   height: 100%;
//   display: grid;
//   grid-template-rows: 55px auto 48px 71px;
// }
.list-content {
  display: flex;
  justify-content: space-between;
}
.list-content .list-title {
  font-size: 18px;
  font-weight: 500;
}
.starLists {
  padding: 0 20px;
}
.el-button {
  outline: none;
}
.flashcardHeader {
  height: 55px;
  justify-content: center;
  align-items: center;
  background-color: #fafafa;
  border-top: 1px solid #e0e0e0;
  color: black;
  font-size: 28px;
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
}

.flashcardHeaderSecond {
  height: 22px;
  justify-content: center;
  align-items: center;
  background-color: #eeeeee;
  // border-bottom: 1px solid #e0e0e0;
  color: black;
  font-size: 14px;
  font-weight: 400;
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
}

.return:hover {
  opacity: 60%;
}

.headerTitle {
  text-align: center;
  width: 100%;
  font-weight: 600;
}

.controlRow {
  height: 55px;
  justify-content: center;
  align-items: center;
}

.remove {
  cursor: pointer;
  font-size: 32px;
  margin-top: 8px;
  margin-left: 16px;
  padding: 3px 8px;
  border: 1px solid #e0e0e0;
  justify-content: center;
  align-items: center;
}
.remove:hover {
  background-color: #fafafa;
}

.star {
  font-size: 34px;
  text-align: right;
  width: 100%;
  font-weight: 400;
}

.starHover {
  color: black;
  cursor: pointer;
}

.starHover:hover {
  color: orange;
}

.starIsSelected {
  color: orange;
  cursor: pointer;
}

.wordRemaining {
  // position: fixed;
  text-align: right;
  height: 48px;
  line-height: 48px;
  font-weight: 500;
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
}

.wordDisplay {
  cursor: pointer;
  overflow: auto;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-size: 34px;
  font-weight: 600;
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
}

.wordDetail {
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 14px;
  font-weight: 500;
}

.exButton {
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  background-color: #e8f5e9;
  color: #42a16a;
  padding: 2px 6px;
}
.note-card {
  font-size: 12px;
  padding: 0 10px;
  display: flex;
  background-color: #ecf6f0;
  color: #42a16a;
  border-radius: 4px;
}
.note-star {
  text-align: center;
  margin: auto 0;
  width: 30px;
  font-size: 12px;
}
.note-star .thumbs {
  font-size: 20px;
  display: block;
  width: 30px;
}
// .note-stared {
//   background: orange;
//   color: #fff;
// }
::v-deep .el-dialog__wrapper {
  width: 95%;
}
::v-deep .el-divider--vertical {
  height: 100%;
  margin: 0 10px;
}
::v-deep .el-dialog__body {
  padding: 10px 20px 10px 20px;
}
::v-deep .el-dialog__title {
  font-weight: 500;
}

::v-deep .el-drawer__body {
  overflow: auto;
}

// .fade-enter-active,
// .fade-leave-active {
//   transition: opacity 2s;
// }
// .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
//   opacity: 0;
// }

.levelButtons {
  background-color: white;
  position: fixed;
  width: 100%;
  bottom: 0;
  -user-select: none;
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
}

.flex-buttons {
  justify-content: center;
  align-items: center;
  text-align: center;
}

.btnBar {
  height: 10px;
}

.btnStyle {
  font-size: 26px;
  border: 1px solid #e0e0e0;
  padding-bottom: 12px;
  font-weight: 600;
}

.btnClick {
  padding-top: 8px;
  cursor: pointer;
}

.drawerItem {
  cursor: pointer;
}

.drawerItem:hover {
  background-color: #e8f5e9;
}
.voice {
  margin-top: 40px;
  font-size: 20px;
}
.note-list {
  display: flex;
  justify-content: space-between;
}
::v-deep .el-divider__text.is-left {
  left: 0;
  font-size: 16px;
}
::v-deep .el-divider__text {
  padding-left: 0;
  color: #42a16a;
}
.demo-block-control {
  margin-top: 10px;
  box-sizing: border-box;
  background-color: #fff;
  text-align: center;
  color: #42a16a;
  cursor: pointer;
  position: relative;
}
.openModal {
  background: #f9f9f9;
  margin: 10px -20px 0px;
  padding: 10px 20px 5px;
}
.note-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}
.note-content small {
  font-weight: normal;
  margin-top: 10px;
  display: inline-block;
}
::v-deep .el-divider {
  margin-bottom: 32px;
}
::v-deep .el-divider__text.is-left {
  left: 0;
  font-size: 20px;
}
::v-deep .el-divider__text {
  padding-left: 8px;
  color: #42a16a;
}
</style>
